// dependencies
import React from 'react'

// components
import Layout from '../layout/layout'
import Text from '../components/Text'
import SubHeader from '../components/Subheader'
import SEO from '../components/seo'
import Return from '../components/Return'

// styles
import styles from './pages.module.css'

const Contact = () => {
    return (
        <Layout>
            <SEO
                title="Request an order"
                description="Orders for Sew Fabulous custom treatments or masks are currently being taken. Fill out the form and we will get back to you as soon as possible."
            />

            <SubHeader>Order Masks or Home Treatments</SubHeader>
            <Text>
                If you'd like to purchase an order of face masks or custom
                treatments, please fill out the form below with your name,
                email, and a short description of your order request.
            </Text>
            <Text>
                Masks can be purchased for $5 each plus applicable shipping.
                Each mask is made from two layers of 100% pre-washed cotton
                fabric with ribbon ties.
            </Text>
            <form
                name="Contact"
                method="POST"
                data-netlify="true"
                aria-label="Contact form."
                className={styles.Form}
            >
                <input type="hidden" name="form-name" value="Contact" />
                <input
                    required
                    className={styles.Input}
                    placeholder="Your name"
                    name="Name"
                    aria-label="Name input."
                />
                <input
                    required
                    className={styles.Input}
                    placeholder="Your email"
                    name="Email"
                    aria-label="Email input."
                />
                <textarea
                    required
                    className={[styles.Input, styles.Textarea].join(' ')}
                    placeholder="A description of your order"
                    rows="5"
                    name="Message"
                    aria-label="Message input."
                ></textarea>
                <button type="Submit" className={styles.Submit}>
                    Submit
                </button>
            </form>
            <Return />
        </Layout>
    )
}

export default Contact
