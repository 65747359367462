// dependencies
import React from 'react'
import { Link } from 'gatsby'

// styles
import styles from './components.module.css'

// icons
import arrow from '../images/back.svg'

const Return = () => {
    return (
        <Link className={styles.Return} to="/">
            <img src={arrow} alt=" " className={styles.ReturnArrow} />
            <p>Back</p>
        </Link>
    )
}

export default Return
